<template>
  <div class="ud-body">
    <a-card :bordered="true">
      <a-form
        ref="form"
        :rules="rules"
        :label-col="labelCol"
        :model="form"
        :wrapper-col="wrapperCol"
        class="form"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="手机号" name="username">
              <a-input
                placeholder="请输入手机号"
                v-model:value="form.username"
                allow-clear
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="姓名" name="realName">
              <a-input
                placeholder="请输入姓名"
                v-model:value="form.realName"
                allow-clear
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="旧电池编号" name="oldBatteryCode">
              <a-input
                placeholder="请输入原电池编号"
                v-model:value="form.oldBatteryCode"
                allow-clear
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="新电池编号" name="batteryCode">
              <a-input
                placeholder="请输入新电池编号"
                v-model:value="form.batteryCode"
                allow-clear
              ></a-input> </a-form-item
          ></a-col>
        </a-row>
        <a-row v-if="expand">
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="换电柜编号" name="cabinetCode">
              <a-input
                placeholder="请输入换电柜编号"
                v-model:value="form.cabinetCode"
                allow-clear
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属运营商" name="operatorId">
              <a-select
                v-model:value="form.operator"
                placeholder="请选择所属运营商"
                allow-clear
              >
                <a-select-option
                  v-for="(item, index) in operatorList"
                  :key="index"
                  :value="item.operatorId"
                  >{{ item.operatorName }}</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="换电时间" name="createTime">
              <a-range-picker
                v-model:value="dateRange"
                :show-time="true"
                value-format="YYYY-MM-DD HH:mm:ss"
                class="ud-fluid"
              >
                <template #suffixIcon>
                  <calendar-outlined />
                </template>
              </a-range-picker> </a-form-item
          ></a-col>
        </a-row>
        <a-row type="flex" justify="end">
          <a-space class="btn-wrap">
            <a-button type="primary" @click="reload"
              ><i class="iconfont icon-zaitu"></i>查询</a-button
            >
            <a-button @click="reset">
              <i class="iconfont icon-mimazhongzhi"></i>重置</a-button
            >
            <span
              v-if="!expand"
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              展开<i class="iconfont icon-xiangxiajiantou"></i>
            </span>
            <span
              v-else
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              收起<i class="iconfont icon-xiangshang_jiantou"></i>
            </span>
          </a-space>
        </a-row>
      </a-form>
    </a-card>
    <a-card :bordered="true" class="table">
      <div>
        <ud-pro-table
          ref="table"
          :rowKey="(record) => record.id"
          :columns="columns"
          :datasource="datasource"
          @change="handleChange"
          :scroll="{ x: 'max-content' }"
        >
          <template #toolbar>
          </template>
          <template #orderCode="{ record }">
            <div style="color: #409eff; text-decoration: underline">
              {{ record.orderCode }}
            </div>
          </template>
          <template #batteryCode="{ record }">
            <div style="color: #409eff; text-decoration: underline">
              {{ record.batteryCode }}
            </div>
          </template>
          <template #point="{ record }">
            <div style="color: #409eff; text-decoration: underline">
              {{ record.point }}
            </div>
          </template>
          <template #customTitle>
            <div>
              所属运营商
              <i
                class="iconfont icon-bukejian"
                style="cursor: pointer"
                v-if="!isShowOperator"
                @click="isShowOperator = true"
              ></i>
              <i
                class="iconfont icon-kejian"
                style="cursor: pointer"
                v-else
                @click="isShowOperator = false"
              ></i>
            </div>
          </template>
          <template #operatorCustom="{ record }">
            <div v-if="!isShowOperator && record.operatorName">
              {{
                record.operatorName.substring(0, 1) +
                new Array(record.operatorName.length).join('*')
              }}
            </div>
            <div v-else>
              {{ record.operatorName }}
            </div>
          </template>
        </ud-pro-table>
      </div>
    </a-card>

    <!-- <operation-detail
      v-model:visible="showDetail"
      :data="current || {}"
    ></operation-detail>
    <operation-edit v-model:visible="showEdit" :data="current" @done="reload" /> -->
  </div>
</template>

<script>
// import operationDetail from './operation-detail.vue'
// import operationEdit from './operation-edit.vue'
import * as operatorApi from '@/api/rnt/operator.js'
import * as orderBatteryApi from '@/api/rnt/orderRecord.js'
import { CalendarOutlined } from '@ant-design/icons-vue'
export default {
  components: {
    CalendarOutlined
    // DownOutlined,
    // operationDetail,
    // operationEdit
  },
  watch: {
    dateRange() {
      if (this.dateRange && this.dateRange.length === 2) {
        this.form.createTimeStart = this.dateRange[0]
        this.form.createTimeEnd = this.dateRange[1]
      } else {
        this.form.createTimeStart = null
        this.form.createTimeEnd = null
      }
    }
  },
  data() {
    return {
      dateRange: [],
      operatorList: [],
      visibleLog: false,
      visibleOrder: false,
      visibleEndTime: false,
      visibleRefund: false,
      visibleBatteryCode: false,
      actionForm: { endTime: '' },
      labelCol: { md: { span: 6 }, sm: { span: 24 } },
      wrapperCol: { md: { span: 16 }, sm: { span: 24 } },
      showDetail: false,
      showEdit: false,
      current: null,
      form: {},
      rules: {},
      expand: false,
      isShowOperator: false,
      columns: [
        {
          title: '序号',
          width: 48,
          // align: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '换电单号',
          // width: 170,
          // align: 'center',
          dataIndex: 'code'
        },
        {
          title: '手机号',
          // width: 85,
          // align: 'center',
          dataIndex: 'username'
        },
        {
          title: '姓名',
          // width: 85,
          // align: 'center',
          dataIndex: 'realName'
        },
        {
          title: '身份证号',
          // width: 160,
          // align: 'center',
          dataIndex: 'idCard'
        },
        {
          title: '订单编号',
          dataIndex: 'orderNumber'
        },
        {
          title: '新电池编号',
          // width: 90,
          // align: 'center',
          dataIndex: 'batteryCode'
        },
        {
          title: '旧电池编号',
          // width: 90,
          // align: 'center',
          dataIndex: 'oldBatteryCode'
        },
        {
          // width: 175,
          // align: 'center',
          slots: {
            title: 'customTitle',
            customRender: 'operatorCustom'
          }
        },
        {
          title: '换电柜编号',
          // width: 175,
          // align: 'center',
          dataIndex: 'cabinetCode'
        },
        {
          title: '换电时间',
          // width: 175,
          // align: 'center',
          dataIndex: 'createTime'
        }
        // {
        //   title: '操作',
        //   width: 200,
        //   dataIndex: 'action',
        //   align: 'center',
        //   slots: {
        //     customRender: 'action'
        //   }
        // }
      ],
      data: [
        {
          exchangeCode: 'CH2201142728258',
          phoneNumber: '18370082684',
          name: '曾嘉文',
          IDNumber: '362427198903170316',
          oldBatteryCode: 'BT307206012YDNO210905398',
          newBatteryCode: 'BT307206012YDNO210905345',
          operator: '厦门远丞科技有限公司',
          cabinetCode: 'CHZD04DCJ0210916004',
          cabinetName: '明发04',
          exchangeTime: '2022-01-11 09:45:36'
        },
        {
          exchangeCode: 'CH2201142728259',
          phoneNumber: '18370082684',
          name: '曾嘉文',
          IDNumber: '362427198903170316',
          oldBatteryCode: 'BT307206012YDNO210905398',
          newBatteryCode: 'BT307206012YDNO210905345',
          operator: '厦门远丞科技有限公司',
          cabinetCode: 'CHZD04DCJ0210916004',
          cabinetName: '明发04',
          exchangeTime: '2022-01-11 09:45:36'
        },
        {
          exchangeCode: 'CH2201142728245',
          phoneNumber: '18370082684',
          name: '曾嘉文',
          IDNumber: '362427198903170316',
          oldBatteryCode: 'BT307206012YDNO210905398',
          newBatteryCode: 'BT307206012YDNO210905345',
          operator: '厦门远丞科技有限公司',
          cabinetCode: 'CHZD04DCJ0210916004',
          cabinetName: '明发04',
          exchangeTime: '2022-01-11 09:45:36'
        }
      ]
    }
  },
  mounted() {
    this.queryOperator()
  },
  methods: {
    // 查询所有运营商
    queryOperator() {
      operatorApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.operatorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 获取表格数据
    datasource(option, callback) {
      orderBatteryApi
        .page({
          ...this.form,
          page: option.page,
          limit: option.limit,
          sort: 'createTime',
          order: 'desc'
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    openDetail(row) {
      this.current = row
      this.showDetail = true
    },
    openEdit(row) {
      console.log(row)
      this.current = row
      this.showEdit = true
    },
    // 表格行自定义事件
    rowClick: (record, index) => {
      return {
        on: {
          click: () => {
            // this.rows.index = index //保存选中行的index
            console.log('当前选中的行' + index, '当前选中行数据' + record)
          }
        }
      }
    },
    // 新增计费模板确定按钮回调事件
    handleBillingOk() {
      this.$refs.billingForm.validate().then((res) => {
        console.log(res)
        this.$message.success('添加计费模板成功！')
        this.visible = false
      })
    },
    /* 刷新表格 */
    reload() {
      this.selection = []
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.dateRange = []
      this.form = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    // 是否展开表单
    expandForm() {
      this.expand = !this.expand
    },
    // 表格改变
    handleChange() {}
  }
}
</script>
<style lang='less' scoped>
.form {
  .btn-wrap {
    padding-right: 20px;
    .iconfont {
      margin-right: 5px;
    }
  }
}
.table {
  margin: 10px 0;
}
</style>
